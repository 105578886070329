<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

                <div class="content_section">
                    <div class="content">
                        <sub-title>쿠폰</sub-title>
                        <div class="board_list" style="padding:20px">
<!--                            <div class="coupon">-->
<!--                                <div class="item">-->
<!--                                    <img src="../../assets/images/coupon/silver.jpg" alt="" style="border: 1px solid silver">-->
<!--                                    <button class="btn-coupon btn-silver" @click="open('s')">OPEN ({{$store.state.userInfo.scoupon}}장)</button>-->
<!--                                </div>-->

<!--                                <div class="item">-->
<!--                                    <img src="../../assets/images/coupon/gold.jpg" alt="" style="border: 1px solid gold">-->
<!--                                    <button class="btn-coupon btn-gold" @click="open('g')">OPEN ({{$store.state.userInfo.gcoupon}}장)</button>-->
<!--                                </div>-->

<!--                                <div class="item">-->
<!--                                    <img src="../../assets/images/coupon/dia.jpg" alt="" style="border: 1px solid lightskyblue">-->
<!--                                    <button class="btn-coupon btn-diamond" @click="open('d')">OPEN ({{$store.state.userInfo.dcoupon}}장)</button>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="coupon">
                                <div class="item">
                                    <img src="../../assets/images/coupon/1.png" alt="" style="border: 1px solid gold">
                                    <button class="btn-coupon btn-gold" @click="open('c')">OPEN ({{$store.state.userInfo.ccoupon}}장)</button>
                                </div>
                                <div class="item">
                                    <img src="../../assets/images/coupon/2.png" alt="" style="border: 1px solid gold">
                                    <button class="btn-coupon btn-gold" @click="open('s')">OPEN ({{$store.state.userInfo.scoupon}}장)</button>
                                </div>

                                <div class="item">
                                    <img src="../../assets/images/coupon/3.png" alt="" style="border: 1px solid gold">
                                    <button class="btn-coupon btn-gold" @click="open('g')">OPEN ({{$store.state.userInfo.gcoupon}}장)</button>
                                </div>
                                <div class="item">
                                    <img src="../../assets/images/coupon/4.png" alt="" style="border: 1px solid gold">
                                    <button class="btn-coupon btn-gold" @click="open('d')">OPEN ({{$store.state.userInfo.dcoupon}}장)</button>
                                </div>
                            </div>

                            <div class="culcheck_history_box">
                                <div>
                                    <table>
                                        <tr>
                                            <th colspan="2">쿠폰 획득내역</th>
                                        </tr>
                                        <tr>
                                            <th>시간</th>
                                            <th>내역</th>
                                        </tr>
                                        <tr v-for="(item,index) in couponGetHistory">
                                            <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>
                                            <td>{{item.content}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <th colspan="2">쿠폰 사용내역</th>
                                        </tr>
                                        <tr>
                                            <th>시간</th>
                                            <th>내역</th>
                                        </tr>
                                        <tr v-for="(item,index) in couponUseHistory">
                                            <td>{{item.createTime|datef('YYYY-MM-DD HH:mm')}}</td>
                                            <td>{{item.content}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>
    </div>

</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import sportsConst from "../../common/sportsConst";
    import SubTitle from "../../components/SubTitle";
    import {getCouponHistory, openCoupon} from "../../network/leisureRequest";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "Coupon",
        mixins:[postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                couponGetHistory:[],
                couponUseHistory:[],
                position:"쿠폰",
            }
        },
        methods: {
            open(type){
                openCoupon(type).then(res=>{
                    if(res.data.success){
                        this.$swal({
                            title: '축하드립니다 ' + res.data.data + '포인트 당첨되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$store.dispatch('actionUserInfo')
                        this.initHistory()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            initHistory(){
                getCouponHistory().then(res=>{
                    if(res.data.success){
                        this.couponGetHistory = res.data.data['getHistory']
                        this.couponUseHistory = res.data.data['useHistory']
                    }
                })
            }
        },
        created() {
            this.initHistory();
        },
    }
</script>

<style scoped>
    @import url("../../assets/css/culcheck.css");

    .coupon{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .coupon .item{
        width: 24.5%;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
    .coupon .item img{
        width: 100%;
        max-width: 250px;
        border-radius: 6px;
    }
    .coupon .item .btn-coupon{
        border-radius: 6px;
        margin-top: 10px;
        padding: 5px 20px;
        width: 250px;
        height: 44px;
        --background: linear-gradient(
                180deg, var(--navbg2),var(--navbg1));
    }
    .coupon .item .btn-silver{
        border: 1px solid silver;
        color: silver;
    }
    .coupon .item .btn-gold{
        border: 1px solid gold;
        color: gold;
    }
    .btn-diamond{
        border: 1px solid lightskyblue;
        color: lightskyblue;
    }

    @media screen and (max-width: 1024px)  {
        .coupon .item{
            width: 99%;
            border-bottom: 2px solid #0b1423;
            padding: 10px;
        }
    }

</style>